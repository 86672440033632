import service from "./request";
//9.0改造接口文件
// 根据父级id查询菜单
export const getLineShiftOrderSchedulesAPI = (data) => {
  return service({
    url: "/manage/schedule/getLineShiftOrderSchedules",
    method: "get",
    data,
  });
};
// 班次调度信息整合
export const getLineShiftOrderSchedulesEncapsulationAPI = (data) => {
  return service({
    url: "/manage/schedule/getLineShiftOrderSchedulesEncapsulation",
    method: "get",
    data,
  });
};
//获取指定班次待派单乘客信息
export const getShiftNotSchedulePassengerOrdersAPI = (data) => {
  return service({
    url: "/order/driver/intercity/getShiftNotSchedulePassengerOrders",
    method: "get",
    data,
  });
};
//获取指定班次司机车次计划
export const getShiftDriverOrdersAPI = (data) => {
  return service({
    url: "/order/driver/intercity/getShiftDriverOrders",
    method: "get",
    data,
  });
};
//批量派车
export const dispatchDriverVehiclesAPI = (data) => {
  return service({
    url: "/order/driver/intercity/dispatchDriverVehicles",
    method: "post",
    data,
  });
};
//待派单乘客
export const getNotSchedulePassengerOrdersAPI = (data) => {
  return service({
    url: "/order/driver/intercity/getNotSchedulePassengerOrders",
    method: "get",
    data,
  });
};
//改派
export const dispatchChangePassengersAPI = (data) => {
  return service({
    url: "/order/driver/intercity/dispatchChangePassengers",
    method: "post",
    data,
  });
};
//调度-改班
export const updateShiftAPI = (data) => {
  return service({
    url: "/manage/shift/intercity/updateShift",
    method: "put",
    data,
  });
};
//获取指定线路ID的返程线路信息
export const getReverseLineAPI = (data) => {
  return service({
    url: "/manage/line/getReverseLine",
    method: "get",
    data,
  });
};
//待派单乘客数量
export const getNotSchedulePassengerOrderTicketNumAPI = (data) => {
  return service({
    url: "/order/driver/intercity/getNotSchedulePassengerOrderTicketNum",
    method: "get",
    data,
  });
};
//获取指定线路司机车次计划
export const getLineDriverOrdersAPI = (data) => {
  return service({
    url: "/order/driver/intercity/getLineDriverOrders",
    method: "get",
    data,
  });
};
//批量派乘客
export const dispatchPassengersAPI = (data) => {
  return service({
    url: "/order/driver/intercity/dispatchPassengers",
    method: "post",
    data,
  });
};
//查看派单乘客订单
export const getPassengerOrdersAPI = (data) => {
  return service({
    url: "/order/driverClient/intercity/getPassengerOrders",
    method: "get",
    data,
  });
};
//删除车次
export const deleteDriverOrderAPI = (data) => {
  return service({
    url: "/order/driver/intercity/deleteDriverOrder",
    method: "delete",
    data,
  });
};
//批量取消派单
export const cancelDispatchPassengersAPI = (data) => {
  return service({
    url: "/order/driver/intercity/cancelDispatchPassengers",
    method: "post",
    data,
  });
};
//线路规则-获取指定渠道线路下购票规则列表
export const getIntercityLineTicketRegulationAPI = (data) => {
  return service({
    url: "/manage/ticketRegulation/getIntercityLineTicketRegulation",
    method: "get",
    data,
  });
};
//修改指定渠道线路下购票规则信
export const updateIntercityLineTicketRegulationAPI = (data) => {
  return service({
    url: "/manage/ticketRegulation/updateIntercityLineTicketRegulation",
    method: "post",
    data,
  });
};
//获取指定渠道线路下退票规则
export const getIntercityLineRefundTicketRegulationAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/getIntercityLineRefundTicketRegulation",
    method: "get",
    data,
  });
};
//新增定制客运退票规则
export const addRefundIntercityRegulationAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/postRefundIntercityRegulation",
    method: "post",
    data,
  });
};
//修改定制客运退票规则
export const updateRefundIntercityRegulationAPI = (data) => {
  return service({
    url: "/manage/refundTicketRegulation/putRefundIntercityRegulation",
    method: "put",
    data,
  });
};
//删除定制客运退票规则
export const deleteRefundIntercityRegulationAPI = (data) => {
  return service({
    url: `/manage/refundTicketRegulation/deleteRefundIntercityRegulation/${data}`,
    method: "delete",
    data,
  });
};
//获取指定渠道线路下改签规则列表
export const getIntercityLineRebookRegulationAPI = (data) => {
  return service({
    url: "/manage/rebookRegulation/getIntercityLineRebookRegulation",
    method: "get",
    data,
  });
};
//修改指定渠道线路下改签规则信息
export const updateIntercityLineRebookRegulationAPI = (data) => {
  return service({
    url: "/manage/rebookRegulation/updateIntercityLineRebookRegulation",
    method: "post",
    data,
  });
};
//司机端规则列表
export const queryIntercityLineRebookRegulationAPI = (data) => {
  return service({
    url: "/manage/intercityDriverRegulation/getIntercityLineDriverRegulation",
    method: "get",
    data,
  });
};
//修改指定渠道线路下司机端规则信息
export const updateIntercityLineDriverRegulationAPI = (data) => {
  return service({
    url: "/manage/intercityDriverRegulation/updateIntercityLineDriverRegulation",
    method: "post",
    data,
  });
};
//获取指定渠道线路下检票规则列表
export const getIntercityLineTicketCheckRegulationAPI = (data) => {
  return service({
    url: "/manage/ticketCheckRegulation/getIntercityLineTicketCheckRegulation",
    method: "get",
    data,
  });
};
//修改指定渠道线路下检票规则信息
export const updateIntercityLineTicketCheckRegulationAPI = (data) => {
  return service({
    url: "/manage/ticketCheckRegulation/updateIntercityLineTicketCheckRegulation",
    method: "post",
    data,
  });
};

//获取指定渠道的检票规则配置列表
export const getAllRegulationByChannelNoAPI = (data) => {
  return service({
    url: "/manage/ticketCheckRegulation/getAllRegulationByChannelNo",
    method: "get",
    data,
  });
};
//编辑
export const updateChannelNoRegulationAPI = (data) => {
  return service({
    url: "/manage/ticketCheckRegulation/updateChannelNoRegulation",
    method: "put",
    data,
  });
};
//判断指定渠道下的乘车码规则是否为启用状态
export const passengerCertificateEnabledTypeIsTrueAPI = (data) => {
  return service({
    url: "/manage/ticketCheckRegulation/passengerCertificateEnabledTypeIsTrue",
    method: "get",
    data,
  });
};
//获取指定渠道，司机行程结束前是否补收票款
export const driverEndOfItineraryPaymentByChannelNoAPI = (data) => {
  return service({
    url: "/manage/ticketCheckRegulation/driverEndOfItineraryPaymentByChannelNo",
    method: "get",
    data,
  });
};
//获取当前用户公司线路信息
export const getCompanyGroupLinesAPI = (data) => {
  return service({
    url:
      "/manage/scheduleLineAssociation/getCompanyGroupLines?companyIds=" + data,
    method: "get",
  });
};
//调度 - 查询指定班次的乘客订单
export const byShiftIntercityIdAPI = (data) => {
  return service({
    url: "/order/intercity/list/byShiftIntercityId",
    method: "get",
    data,
  });
};
