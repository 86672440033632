<template>
  <div class="addBindLine">
    <div class="return" @click="returnList">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <el-form
      ref="ruleForm"
      :model="form"
      label-width="95px"
      label-position="right"
    >
      <el-form-item
        label="用户："
        prop="userName"
        :rules="{
          required: true,
          validator: (rule, value, callback) => {
            if (!this.tableData1.length) callback('请选择用户')
            else callback()
          },
          trigger: 'blur',
        }"
      >
        <el-button
          v-if="!tableData1.length"
          type="text"
          @click="dialogVisible = true"
          >请选择</el-button
        >
        <div v-else style="width: 500px">
          <el-button
            v-if="!$route.query.id"
            type="text"
            @click="dialogVisible = true"
            >重新选择</el-button
          >
          <Table
            table-height="100px"
            :table-data="tableData1"
            :title-name="titleName"
          ></Table>
        </div>
      </el-form-item>
      <el-form-item prop="bindChannelNos" label="绑定渠道：">
        <el-select
          v-model="form.bindChannelNos"
          multiple          
          collapse-tags
          placeholder="请选择绑定渠道"
        >
          <el-option
            v-for="item in channelList"
            :key="item.channelNo"
            :label="item.channelName"
            :value="item.channelNo"
            :disabled="item.disabled"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="label-form">
      <div class="label">绑定线路：</div>
      <el-checkbox
        :indeterminate="isIndeterminate"
        v-model="checkAll"
        @change="handleCheckAllChange"
        >全选</el-checkbox
      >
    </div>
    <div class="list">
      <div v-for="(item, index) in lineList" :key="index" class="list-item">
        <check-lines
          :itemData="item"
          :checkedValue="item.checkAll"
          :index="index"
          @change="itemChange"
        ></check-lines>
      </div>
    </div>
    <div class="footer">
      <el-button @click="returnList">取 消</el-button>
      <el-button type="primary" @click="saveFun">保 存</el-button>
    </div>
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      title="添加用户"
      width="980px"
      @close="cancelFun"
    >
      <el-form inline :model="form" label-width="95px" label-position="right">
        <el-form-item label="用户姓名：">
          <el-input
            v-model.trim="userForm.userName"
            placeholder="请输入"
            clearable
            @input="handleInput($event, 'userName')"
          ></el-input>
          <!-- <el-select
            v-model="userForm.userName"
            filterable
            remote
            reserve-keyword
            placeholder="搜索姓名"
            :disabled="!userForm.companyId"
            clearable
            :remote-method="queryDriver"
            @change="selectUser"
          >
            <el-option
              v-for="item in driverList"
              :key="item.id"
              :disabled="item.disabled"
              :label="item.userName"
              :value="item.userName"
            >
            </el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item label="用户名：">
          <el-input
            v-model.trim="userForm.loginName"
            placeholder="请输入"
            clearable
            @input="handleInput($event, 'loginName')"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属公司：">
          <companySelectForSearch
            :multiple="false"
            :searchable="true"
            :props="{
              value: 'id',
              label: 'name',
              children: 'children',
            }"
            @getValue="getGroupId"
            @getName="getName"
          />
        </el-form-item>
      </el-form>
      <Table
        ref="table"
        table-height="442px"
        :table-data="tableData2"
        :title-name="titleName"
        :empty-text="userForm.companyId ? '暂无数据' : '请先选择公司'"
        :highlight-current-row="true"
        :table-row-class-name="tableRowClassName"
        @row-click="rowClick"
      ></Table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          @size-change="onSizeChange"
          @current-change="onCurrentChange"
        ></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancelFun">取 消</el-button>
        <el-button size="small" type="primary" @click="confirmFun"
          >确 认</el-button
        >
      </span></el-dialog
    >
  </div>
</template>

<script>
import {
  addNewLineAPI,
  queryChannelListAPI,
  scheduleLineAssociationListAPI,
  getUsersByCompanyIdAPI,
  queryScheduleLineAssociationVOInfo,
  updateLineInfoAPI,
  scheduleLineAssociationTableDataAPI,
} from "@/api/lib/api.js";
import { getCompanyGroupLinesAPI } from "@/api/lib/remouldAPI.js";
import companySelectForSearch from "@/components/treeSelect/companySelectForSearch.vue";
import checkLines from "./checkLines.vue";
export default {
  components: {
    companySelectForSearch,
    checkLines,
  },
  data () {
    return {
      dialogVisible: false,
      channelList: [],
      userForm: {
        userName: null,
        companyId: null,
        loginName: null,
      },
      form: {
        bindChannelNos: [],
        bindLineIds: [],
        bindLineNames: [],
        companyIds: [],
        companyNames: [],
        loginName: "",
        phone: "",
        userId: null,
        userName: "",
      },
      tableData1: [],
      tableData2: [],
      titleName: [
        {
          title: "用户姓名",
          props: "userName",
        },
        {
          title: "用户名",
          props: "loginName",
        },
        {
          title: "联系方式",
          props: "phone",
        },
        {
          title: "所属公司",
          props: "companyNames",
          SpecialJudgment: (res) => {
            return res.join("、");
          },
        },
      ],
      lineList: [],
      userList: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      driverList: [],
      obj: {},
      isIndeterminate: true,
      checkAll: false,
      // tempBindChannelNos:[],//缓存选中的线路渠道，设置绑定渠道的禁用状态
    };
  },
  watch: {
    lineList: {
      handler (val) {
        this.computedBindLinesChannelNos();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted () {
    const id = this.$route.query.id;
    if (id) {
      queryScheduleLineAssociationVOInfo(id).then(async res => {
        if (res.code == "SUCCESS") {
          await this.getQueryChannelList();
          this.form = res.data;
          this.form.bindChannelNos = this.form.bindChannelNos.filter((e) =>
            this.channelList.find((i) => i.channelNo == e)
          );
          this.tableData1 = [
            {
              loginName: res.data.loginName,
              userName: res.data.userName,
              phone: res.data.phone,
              companyNames: res.data.companyNames,
            },
          ];
          this.queryCompanyGroupLines(res);
        }
      });
    } else {
      this.getQueryChannelList();
    }
    this.renderData();
  },
  methods: {
    //封装一个方法，输入是当前所选线路集合，输出是当前所选线路集合对应的绑定渠道
    //初试的时候会调，change的是会调
    computedBindLinesChannelNos () {
      const bindChannelNos = [];//缓存已选线路的渠道---设置禁用状态
      this.lineList.map(item => {
        item.lines.map(e => {
          if (e.checked) {
            !this.form.bindChannelNos.some((i) => i === e.channelNo) &&
              this.form.bindChannelNos.push(e.channelNo);//更新选中的渠道值，线路所属渠道选中，原本的选择器的值保留
            !bindChannelNos.some((i) => i === e.channelNo) &&
              bindChannelNos.push(e.channelNo);
          }
        })
      });
      this.channelList.map(element => {
        element.disabled = bindChannelNos.some((i) => i === element.channelNo);
      })
    },
    itemChange (val, index) {
      this.lineList[index] = val;
      let num = 0;
      let innerNum = 0;
      let length = 0;
      this.lineList.map((item) => {
        item.checkItem && num++;
        length += item.lines.length;
        item.lines.map((i) => {
          i.checked && innerNum++;
        });
      });
      this.checkAll = num === this.lineList.length;
      this.isIndeterminate = innerNum > 0 && innerNum < length;
      this.computedBindLinesChannelNos();
    },
    //所有全选控制
    handleCheckAllChange (val) {
      this.isIndeterminate = false;
      this.changeCheckStatus(this.checkAll);
    },
    changeCheckStatus (flag) {
      this.lineList?.map((k) => {
        k.isIndeterminate = false;
        k.checkItem = flag;
        k.lines.map((item) => {
          item.checked = flag;
        });
      });
      this.computedBindLinesChannelNos();
    },
    handleInput (e, val) {
      this.userForm[val] = e;
      setTimeout(() => {
        this.renderData();
      }, 300);
    },
    // 返回列表
    returnList () {
      this.$router.push("/projectTravel/bindLine");
    },
    //获取渠道数据
    getQueryChannelList () {
      return queryChannelListAPI({ status: 0, companyId: null }).then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data.filter(item => {
            item.disabled = false;
            return item
          });
        }
      });
    },
    getGroupId (v) {
      this.pageSize = 10;
      this.currentPage = 1;
      if (v) {
        this.userForm.companyId = v;
        this.form.companyId = v;
      } else {
        this.userForm.companyId = null;
        this.form.companyId = null;
      }
      this.renderData();
    },
    getName (val) {
      this.form.companyName = val;
    },
    queryDriver (query) {
      this.driverList = [];
      if (query !== "") {
        setTimeout(() => {
          const data = {
            userName: query,
            companyId: this.userForm.companyId,
          };
          getUsersByCompanyIdAPI(data).then((res) => {
            if (res.code === "SUCCESS") {
              let list = res.data;
              scheduleLineAssociationTableDataAPI({
                companyId: this.userForm.companyId, //公司ID
                currentPage: 1, //页数
                pageSize: 10000, //分页大小
              }).then((result) => {
                let arr = result.data.list;
                for (let i = 0; i < arr.length; i++) {
                  for (let j = 0; j < list.length; j++) {
                    if (arr[i].userId == list[j].userId) {
                      list[j].disabled = true;
                      break;
                    }
                  }
                }
                this.driverList = list;
              });
            }
          });
        }, 200);
      } else {
        this.driverList = [];
      }
    },
    // selectUser () {
    //   this.pageSize = 10;
    //   this.currentPage = 1;
    //   this.renderData();
    // },
    rowClick (e) {
      if (!e.disabled) {
        this.form.userName = e.userName;
        this.form.phone = e.phone;
        this.form.loginName = e.loginName;
        this.form.userId = e.userId;
        this.form.companyIds = e.companyIds;
        this.form.companyNames = e.companyNames;
        this.obj = e;
      } else {
        this.$refs.table.disabledRow();
      }
    },
    // 分页大小change
    onSizeChange (val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.tableData2 = this.userList.slice(0, val * this.currentPage);
    },
    // 分页页数改变change
    onCurrentChange (val) {
      this.currentPage = val;
      this.tableData2 = this.userList.slice(
        (val - 1) * this.pageSize,
        val * this.pageSize
      );
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.disabled) {
        return "disabled-row";
      } else return "";
    },
    renderData () {
      getUsersByCompanyIdAPI(this.userForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.total = res.data.length;
          let list = res.data;
          scheduleLineAssociationTableDataAPI({
            companyId: this.userForm.companyId, //公司ID
            currentPage: 1, //页数
            pageSize: 10000, //分页大小
          }).then((result) => {
            let arr = result.data.list;
            for (let i = 0; i < arr.length; i++) {
              for (let j = 0; j < list.length; j++) {
                if (arr[i].userId == list[j].userId) {
                  list[j].disabled = true;
                  break;
                }
              }
            }
            this.userList = list;
            this.total = list.length;
            this.tableData2 = this.userList.slice(
              0,
              this.pageSize * this.currentPage
            );
          });
        }
      });
    },
    confirmFun () {
      if (!this.form.loginName) {
        this.$message.warning("请选择用户！");
        return;
      }
      this.dialogVisible = false;
      this.cancelFun();
      this.tableData1 = [];
      this.tableData1.push(this.obj);
      this.queryCompanyGroupLinesFirst(this.obj);
    },
    //选中之后-默认全选
    queryCompanyGroupLinesFirst (obj) {
      getCompanyGroupLinesAPI(obj.companyIds).then((result) => {
        if (result.code == "SUCCESS") {
          let list = [...result.data];
          list.map((k) => {
            k.isIndeterminate = false;
            k.checkItem = true;
            k.lines.map((item) => {
              item.checked = true;
            });
          });
          this.checkAll = true;
          this.isIndeterminate = false;
          this.lineList = list;
        }
      });
    },
    //获取公司线路
    queryCompanyGroupLines (res) {
      getCompanyGroupLinesAPI(res.data.companyIds).then((result) => {
        if (result.code == "SUCCESS") {
          let list = [...result.data];
          const arr = res?.data.bindLineIds;
          let allNum = 0, length = 0, itemNum = 0;
          list.map((k) => {
            k.isIndeterminate = true;
            k.checkItem = false;
            let num = 0;
            length += k.lines.length;
            k.lines.map((item) => {
              if (arr?.some((e) => e == item.id)) {
                item.checked = true;
                num++;
              } else {
                item.checked = false;
              }
            });
            allNum += num;
            //初始化check状态
            k.checkItem = !k.lines.some((e) => !e.checked);
            k.isIndeterminate = num > 0 && num < k.lines.length;
            k.checkItem && itemNum++;
          });
          this.lineList = list;
          this.checkAll = itemNum === this.lineList.length;
          this.isIndeterminate = allNum > 0 && allNum < length;
        }
      });
    },
    cancelFun () {
      // this.userForm = {
      //   userName: null,
      //   companyId: null,
      //   loginName: null,
      // };
      this.dialogVisible = false;
      // this.tableData2 = [];
      // this.total = 0;
    },
    saveFun () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const bindLineIds = [];
          const bindLineNames = [];
          // const bindChannelNos = [];
          this.lineList.forEach((item) => {
            item.lines.map((e) => {
              if (e.checked) {
                bindLineIds.push(e.id);
                bindLineNames.push(e.name);
                //获取渠道号，去重提交
                /**
                 * 解决线路选择，渠道清空的问题，在提交的时候再给他加回去选择线路所属渠道
                 */
                !this.form.bindChannelNos.some((i) => i === e.channelNo) &&
                this.form.bindChannelNos.push(e.channelNo);
              }
            });
          });
          this.form.bindLineIds = bindLineIds;
          this.form.bindLineNames = bindLineNames;
          // this.form.bindChannelNos = bindChannelNos;
          if (!this.$route.query.id) {
            addNewLineAPI(this.form).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("添加新线路成功");
                this.returnList();
              }
            });
          } else {
            updateLineInfoAPI(this.form).then((res) => {
              if (res.code == "SUCCESS") {
                this.$message.success("编辑线路成功");
                this.returnList();
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.el-table .disabled-row {
  color: #d5d5d5;
}
</style>

<style scoped lang="scss">
.addBindLine {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100% - 32px);
  .return {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  .label-form {
    display: flex;
    justify-items: center;
    .label {
      width: 95px;
      text-align: right;
    }
  }
  .list {
    width: calc(100% - 95px);
    padding: 16px 0 4px 95px;
    height: calc(100% - 260px);
    overflow: auto;
    .list-item {
      margin-bottom: 12px;
    }
  }
  .footer {
    padding: 14px 0 0 95px;
    position: fixed;
    bottom: 36px;
  }
}
</style>
