<template>
  <div class="">
    <p>
      {{ lineData.companyName }}
      <el-checkbox
        :indeterminate="lineData.isIndeterminate"
        v-model="lineData.checkItem"
        @change="handleCheckItemChange"
        style="margin-left: 10px"
        >全选</el-checkbox
      >
    </p>
    <el-checkbox
      :class="['single-check', i.checked ? 'isCheck' : '']"
      v-model="i.checked"
      v-for="(i, d) in lineData.lines"
      :key="d"
      @change="handleChange($event,d)"
      >{{ i.name }}</el-checkbox
    >
  </div>
</template>
<script>
export default {
  props: {
    itemData: {
      type: Object,
      default: {},
    },
    checkedValue: {
      type: Boolean,
      default: true
    },
    index:{
      type:Number,
      default:null
    }
  },
  data () {
    return {
      lineData:{},
    }
  },  
  watch:{
    itemData:{
      handler(newV, oldV) {
        this.lineData = this.deepClone(this.itemData);
      },
      deep: true,
    }
  },
  mounted(){
    this.lineData = this.deepClone(this.itemData);
  },
  methods: {
    //子项公司选中
    handleCheckItemChange (val) {
      this.lineData.isIndeterminate = false;
      this.lineData.lines.map(item=>{
        item.checked = val;
      });
      this.$emit('change',this.lineData,this.index);
    },
    handleChange(val,index){
      let num = 0;
      this.lineData.lines.map(item=>{
        item.checked && num++;
      })
      this.lineData.checkItem = num === this.lineData.lines.length;
      this.lineData.isIndeterminate = num > 0 && num < this.lineData.lines.length;
      this.$emit('change',this.lineData,this.index);
    },
  }
}
</script>
<style lang="scss" scoped>
p {
  color: #4d4d4d;
  font-size: 16px;
  margin-bottom: 12px;
}
/deep/ .single-check {
  padding: 6px 12px;
  margin-bottom: 8px;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
}
.isCheck {
  border: 1px solid #336ffe;
}
</style>